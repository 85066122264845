import { memo, useEffect, useState } from "react";

import { CalendarState, DatepickerType, FlexibleState } from "@/types/search";
import { DatesFilterForm, defaultFormState } from "@/components/DatesFilter/Form";
import { ClearButton } from "@/components/ClearButton";
import { DateCopy } from "@/components/SearchBar/DateCopy";
import { DatesFilterFormState } from "@/components/DatesFilter/types";

import { SPopoverTriggerButton } from "./Popovers.styles";
import useDetectRefClick from "@/hooks/useDetectRefClick";
import { Popover } from "@plum/ui-core";
import { IconCross } from "@plum/ui-icons";

interface IDatesPopoverProps {
  calendar: CalendarState;
  flexible: FlexibleState;
  type: DatepickerType;
  isOpenTrigger?: boolean;
  onChange?: (value: DatesFilterFormState) => void;
}

export const DatesPopover = memo(
  ({ calendar, flexible, type, onChange, isOpenTrigger }: IDatesPopoverProps) => {
    const [localValues, setLocalValues] = useState<DatesFilterFormState>({
      calendar,
      flexible,
      type,
    });

    const [isOpen, setIsOpen] = useState(false);
    const { ref: clearRef, isClick: isClearButtonClick } = useDetectRefClick<HTMLButtonElement>();
    const { ref: buttonRef, isClick: isButtonRefClick } = useDetectRefClick<HTMLButtonElement>();

    useEffect(() => {
      setLocalValues({ calendar, flexible, type });
    }, [calendar, flexible, type]);

    useEffect(() => {
      if (isOpenTrigger) {
        setIsOpen(true);
      }
    }, [isOpenTrigger]);

    useEffect(() => {
      onChange?.(localValues);
    }, [isOpen]);

    const hasInput =
      localValues.calendar.checkIn ||
      (localValues.flexible.months && localValues.flexible.months.length > 0);

    const handleClearButton = () => {
      setLocalValues(defaultFormState);
    };

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        e.stopPropagation();
        e.target.form?.dispatchEvent(new Event("submit", { cancelable: false, bubbles: true }));
      }
      if (e.key === "Escape") {
        return setIsOpen(false);
      }
    };

    return (
      <Popover.Root open={isOpen}>
        <Popover.Anchor asChild>
          <div
            className="flex items-center h-full w-full relative"
            data-testid="desktop-date-filter"
          >
            <button
              className={SPopoverTriggerButton()}
              type="button"
              heap-ignore="true"
              onClick={() => !isOpen && setIsOpen(true)}
              ref={buttonRef}
              onKeyDown={handleKeyDown}
              onFocus={() => setIsOpen(true)}
            >
              <DateCopy
                dates={{
                  calendar: localValues.calendar,
                  flexible: localValues.flexible,
                }}
                datepickerType={localValues.type}
              />
            </button>
            {hasInput && isOpen && (
              <ClearButton ref={clearRef} className="absolute right-3">
                <IconCross _size="xs" />
              </ClearButton>
            )}
          </div>
        </Popover.Anchor>
        <Popover.Content
          side="bottom"
          avoidCollisions={true}
          align="start"
          className="w-[786px]"
          onInteractOutside={(e) => {
            if (isButtonRefClick(e)) {
              return;
            }
            if (isClearButtonClick(e)) {
              handleClearButton();
            }
            return setIsOpen(false);
          }}
        >
          <DatesFilterForm
            initialValue={{
              calendar,
              flexible,
              type,
            }}
            isDesktop={true}
            onChange={(value) => {
              setLocalValues(value);
            }}
          />
        </Popover.Content>
      </Popover.Root>
    );
  }
);

DatesPopover.displayName = "DatesPopover";
